<template>
  <div class="case-additional-widget" :style="styles">
    <div v-if="coinsText" class="case-additional-widget__coins">
      <div class="case-additional-widget__coins-amount">
        <span>+</span>
        <NuxtImg v-if="coinIcon" :src="coinIcon" />
        <span>{{ coinsText }}</span>
      </div>
      <span class="case-additional-widget__coins-label">{{ $t('cases.forOpen') }}</span>
      <SharedKitTooltip class="case-additional-widget__coins-tooltip" position="top">
        <TooltipDescriptionCoin />
      </SharedKitTooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICaseAdditionalWidgetProps } from '~/features/cases/widgets/CaseAdditionalWidget/CaseAdditionalWidget.types';
import { useEventStore } from '~/store/event/event.store';

const props = defineProps<ICaseAdditionalWidgetProps>();
const { itemCase } = toRefs(props);

const eventStore = useEventStore();
const { coinIcon, coinColor } = storeToRefs(eventStore);

const coinsText = computed(() => {
  if (!itemCase.value?.tastyCoins?.min && !itemCase.value?.tastyCoins?.max) return null;

  const { min, max } = itemCase.value.tastyCoins;
  return min === max ? min : `${min}-${max}`;
});

const styles = computed(() => ({
  '--event-color': coinColor.value,
}));
</script>

<style scoped lang="scss" src="./CaseAdditionalWidget.scss" />
