import { useCaseStore } from '~/features/cases/store/useCases.store';
import { useQsInventory } from '~/features/quickSale/store/qs.inventory';
import { useAuthStore } from '~/features/authentication/store/authorization';

export const useCasesUiStore = defineStore('cases/ui', () => {
  const UNAUTHORIZED_BUTTONS_HEIGHT = 72;
  const $buttons = ref();

  const { accessToken } = storeToRefs(useAuthStore());

  const { isLoaded } = storeToRefs(useQsInventory());

  const buttonsHeight = ref<number>(accessToken.value ? 0 : UNAUTHORIZED_BUTTONS_HEIGHT);

  const { buttonsGroupDataLoaded } = storeToRefs(useCaseStore());

  const calculateButtonsHeight = () => {
    buttonsHeight.value = $buttons.value && +window.getComputedStyle($buttons.value).height.replace('px', '');
  };

  const calculateAfterDelay = async () => {
    await GlobalUtils.Functions.delay(1400);
    calculateButtonsHeight();
  };

  watch([isLoaded, buttonsGroupDataLoaded, accessToken], async () => {
    await nextTick();
    calculateButtonsHeight();
  });

  watch(buttonsGroupDataLoaded, calculateAfterDelay);

  return {
    calculateButtonsHeight,
    calculateAfterDelay,
    buttonsHeight,
    $buttons,
  };
});
