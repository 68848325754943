<template>
  <div
    :class="['carousel-cases-wrapper', { 'wrapper-animation': carouselsCount && carouselsCount > 1 }]"
    :style="cssVars"
  >
    <slot name="carousel" />
    <div ref="$buttons" class="carousel-cases-wrapper__buttons">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICarouselCasesWrapper } from './CarouselCasesWrapper.types';
import { Breakpoints } from '~/constants/media.constants';
import { useCasesUiStore } from '~/features/cases/store/useCases.ui.store';

const MOBILE_BUTTON_HEIGHT = 40;

const casesUIStore = useCasesUiStore();
const { buttonsHeight, $buttons } = storeToRefs(casesUIStore);

const props = withDefaults(defineProps<ICarouselCasesWrapper>(), {
  carouselsCount: 1,
});

const viewport = useViewport();

const route = useRoute();

const isBkPage = computed(() => route.meta.isIntegratorModule);

const wrapperHeight = computed<string>(() => {
  if (props.isMulticast) return 'auto';
  if (!props.isAuth) return viewport.isGreaterOrEquals(Breakpoints.md) ? '217px' : '164px';
  if (props.available) return '201px';
  return viewport.isGreaterOrEquals(Breakpoints.md) ? '280px' : '240px';
});

const carouselHeightByViewport = computed<number>(() => {
  if (viewport.isGreaterThan(Breakpoints.md)) {
    switch (props.carouselsCount) {
      case 2:
        return 176;
      case 3:
        return 168;
      default:
        return 165.3;
    }
  }
  switch (props.carouselsCount) {
    case 2:
      return 116;
    case 3:
      return 112;
    default:
      return 110.7;
  }
});

const carouselsCountToCalculateWrapperHeight = computed<number>(() =>
  props.carouselsCount > 1 ? props.carouselsCount - 1 : props.carouselsCount,
);

const wrapperMinHeight = computed<string>(() =>
  (props.isMulticast || props.isMultifix) && props.carouselsCount
    ? `${carouselsCountToCalculateWrapperHeight.value * carouselHeightByViewport.value}px`
    : 'auto',
);

const isBtnHeightLargerThanMobileBtnHeight = computed(() => buttonsHeight.value > MOBILE_BUTTON_HEIGHT);

const buttonsOffset = computed<number>(() => {
  if (viewport.isGreaterThan(Breakpoints.md)) return 16;
  if (viewport.isGreaterThan(Breakpoints.sm)) return isBkPage.value ? 16 : 0;
  return isBkPage.value ? 56 : 60;
});

const buttonsPositionBottom = computed(() => {
  const isNegative = isBtnHeightLargerThanMobileBtnHeight.value || viewport.isGreaterThan(Breakpoints.sm);
  return `${Math.abs(buttonsHeight.value - buttonsOffset.value) * (isNegative ? -1 : 1)}px`;
});

const cssVars = computed((): Record<string, string> => {
  return {
    '--buttons-position-bottom': buttonsPositionBottom.value,
    '--wrapper-height': wrapperHeight.value,
    '--wrapper-min-height': wrapperMinHeight.value,
    '--buttons-transition-fn':
      props.isMulticast || props.isMultifix ? 'bottom 0.4s cubic-bezier(0.65, 0, 0.35, 1)' : '',
  };
});

const onResize = () => {
  casesUIStore.calculateButtonsHeight();
};

onMounted(async () => {
  window.addEventListener('resize', onResize);
  onResize();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>

<style scoped lang="scss" src="./CarouselCasesWrapper.scss"></style>
